<template>
  <div>
    <span v-if="label" :class="{'text--disabled': disabled }">
      {{label}}
    </span>
    <VRow>
      <VCol cols="6">
        <VSelect
          dense
          outlined
          label="Year"
          v-model="year"
          :items="yearOptions"
          @input="emit()"
          :rules="rules.concat(yearRules)"
          :disabled="disabled"
          :id="$attrs.id + '-year'"
          :data-test="$attrs['data-test'] + '-year'"
        />
      </VCol>
      <VCol cols="6">
        <VSelect
          dense
          outlined
          label="Month"
          v-model="month"
          :items="monthOptions"
          @input="emit()"
          :rules="rules.concat(monthRules)"
          :disabled="disabled"
          :id="$attrs.id + '-month'"
          :data-test="$attrs['data-test'] + '-month'"
        />
      </VCol>
    </VRow>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    value: { default: null },
    label: { default: null },
    yearRules: { 
      type: Array,
      default: ()=>[]
     },
    monthRules: { 
      type: Array,
      default: ()=>[]
     },
    rules: { 
      type: Array,
      default: ()=>[]
     },
    maxYear: {
      type: Number,
      default: moment().add(10, 'Y').year()
    },
    minYear: {
      type: Number,
      default: moment().subtract(20, 'Y').year()
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      year: this.value==null ? null : moment( this.value ).year(),
      month: this.value==null ? null : moment(this.value).month() + 1
    };
  },
  computed: {
    yearOptions(){
      const options = Array.from({length: this.maxYear-this.minYear}, (_, i) => i + this.minYear)
      options.unshift({value: null, text: ""})
      return options
    },
    monthOptions(){
      const options = Array.from({length: 12}, (_, i) => i + 1)
      options.unshift({value: null, text: ""})
      return options
    }
  },
  methods: {
    emit(){
      this.$emit('input', `${this.year}-${this.month}-01`)
    }
  },
};
</script>

<style>
</style>