<template>
  <VDialog v-model="showModal" persistent>
    <VCard elevation="2">
      <VCardTitle>
        {{t('title')}}
      </VCardTitle>
      <VCardText>
        <VForm ref="form" lazy-validation>
          <h4>{{t('message')}}</h4>
          <VTextarea v-model="message" :placeholder="t('message_placeholder')" :rules="messageRule" outlined/>
        </VForm>
      </VCardText>
      <VCardActions>
        <VSpacer></VSpacer>
        <VBtn @click="emit()" text>
          {{t('close')}}
        </VBtn>
        <VBtn @click="sendInquiry()" :disabled="!message" color="success">
          {{t('submit')}}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { UserSendInquiryCommandService } from "hfapi"

export default {
  props: {
    showModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      submitting: false,
      message: "",
      messageRule:[ v => !!v || this.$t('shared.Validations.required') ],
    }
  },
  methods: {
    t(key, options){
      return this.$t(`organisms.InquiryDialog.${key}`, options)
    },
    sendInquiry() {
      if (this.$refs.form.validate()) {
        UserSendInquiryCommandService.execute({message: this.message, referer: this.$route.path}).then( () => {
          this.emit()
          this.flash(this.t("success"), "success")
        } )
      }
    },
    emit() {
      this.$refs.form.reset()
      this.$emit('close')
    },
  }
}
</script>

<style scoped>
  .v-card__title {
    word-break: break-word !important;
  }
</style>