<template>
  <VRadioGroup :value="value" @change="$emit('input', $event)" v-bind="$attrs" :rules="rules" row>
    <VRadio :label="t('Yes')" :value="true"/>
    <VRadio :label="t('No')" :value="false"/>
  </VRadioGroup>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "YesNoSelector",
  props: {
    value: {},
    rules: {
      type: Array,
      default: ()=>[]
    },
  },
  methods: {
    t(key, options){
      return this.$t(`shared.Locales.${key}`, options)
    },
  },
}
</script>

<style>

</style>