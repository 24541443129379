<template>
  <VChipGroup active-class="primary--text" multiple column>
    <VChip v-for="chip in chips" :key="chip.key" @click="chipClicked(chip)" filter :input-value="chip.active" :data-test="`chip-group-${chip.key}`">
      {{ chip.text }}
    </VChip>
  </VChipGroup>
</template>

<script>
// import { UsecaseServiceName } from "hfapi"
export default {
  name: "BaseMultipleSelectChipGroup",
  model: {
    prop: "chipStatus",
    event: "change",
  },
  props: {
    chips: {
      // [{ key: , text: , active: , }]
      type: Array,
      default: () => { return [] }
    },
    chipStatus: {
      type: Object,
      default: () => { return {} }
    }
  },
  data(){
    return {
      testactive: false
    }
  },
  methods: {
    test(){
      this.testactive = !this.testactive
    },
    chipClicked(chip){
      chip.active = !chip.active
      this.setChipState()
      this.$emit("change", this.chipStatus)
    },
    setChipState(){
      this.chips.forEach(chip => {
        this.$set(this.chipStatus, chip.key, chip.active)
      });
    },
    setChipsActive(){
      this.chips.forEach(chip => {
        this.$set(chip, "active", this.chipStatus[chip.key] ? true : false )
      })
    }
  },
  created(){
    this.setChipsActive()
    this.setChipState()
  },
  watch: {
    chipStatus: {
      deep: true,
      handler(){
        this.setChipsActive()
      }
    }
  }
}
</script>

<style scoped>
</style>
