import router from "@/config/routes"
import store from "@/store"

router.beforeEach((to, from, next)=>{
  // queryパラメータにlangが含まれていたらpage_language_idをupdateする
  if (to.query.lang){
    store.commit("setPageLang", to.query.lang);
  }
  // userのデータを取得
  store.dispatch("user/update", () => {
    // ログイン済みで初期化していない人は強制的にInitializeProfilePageへ
    // public, limited は除外
    // ログイン済 & Initializedされていない && (Initializedされていなくても許可されるものじゃない && RecruitmentShowはpublicだけどアウト)
    if ( ( store.getters["auth/isSignIn"] && !store.state.user.is_initialized ) && ( ( to.name != "InitializeProfilePage" && to.name != "LogOutPage" && to.meta.auth != "public" && to.meta.auth != "limited" && to.meta.auth != "own_application" )) ) {
      next({ name: "InitializeProfilePage", query: {redirect: to.path} });
      return
    }

    // 通常時
    switch (to.meta.auth) {

      case "public":
        !shouldAddRedirectPath(to, from) ? next() : next({name: to.name, query: Object.assign(to.query, {redirect: getRedirectPath(to, from)})}) // redirectPathを付けなきゃ行けないときは、redirectPathを付けて次のページへ
        break;

      case "log_in":
        if ( store.getters["auth/isSignIn"] ) !shouldAddRedirectPath(to, from) ? next() : next({name: to.name, query: Object.assign(to.query, {redirect: getRedirectPath(to, from)})}) // redirectPathを付けなきゃ行けないときは、redirectPathを付けて次のページへ
        else next({ name: "SignUpPage", query: { redirect: to.path } })
        break;

      // initializeProfile していなくても閲覧できる権限
      case "limited":
        if ( store.getters["auth/isSignIn"] ) !shouldAddRedirectPath(to, from) ? next() : next({name: to.name, query: Object.assign(to.query, {redirect: getRedirectPath(to, from)})}) // redirectPathを付けなきゃ行けないときは、redirectPathを付けて次のページへ
        else next({ name: "SignUpPage", query: { redirect: to.path } })
        break;

      case "not_log_in":
        if ( !store.getters["auth/isSignIn"] ) !shouldAddRedirectPath(to, from) ? next() : next({name: to.name, query: Object.assign(to.query, {redirect: getRedirectPath(to, from)})}) // redirectPathを付けなきゃ行けないときは、redirectPathを付けて次のページへ
        else to.query.redirect ? next(to.query.redirect) : next("/recruitments");
        break;
          
      case "own_application":
        if ( store.state.user.isOwnApplication(to.params.id) ) !shouldAddRedirectPath(to, from) ? next() : next({name: to.name, query: Object.assign(to.query, {redirect: getRedirectPath(to, from)})}) // redirectPathを付けなきゃ行けないときは、redirectPathを付けて次のページへ
        else if (store.getters["auth/isSignIn"] ) to.query.redirect ? next(to.query.redirect) : next("/recruitments")
        else next({ name: "SignUpPage", query: { redirect: to.path } })
        break;

      default:
        !shouldAddRedirectPath(to, from) ? next() : next({name: to.name, query: Object.assign(to.query, {redirect: getRedirectPath(to, from)})}) // redirectPathを付けなきゃ行けないときは、redirectPathを付けて次のページへ
        break;

    }

  });
})

function shouldAddRedirectPath(to, from){
  // toの後にredirectするべきページが存在する && もともとのtoのURLにredirect_pathのが存在しない(何か意図的にリダイレクトさせたいページが存在しない) 
  // のときにredirectのqueryパラメータ追加の必要あり。
  return !!getRedirectPath(to, from) && !to.query.redirect
}

function getRedirectPath(to, from){
  // redirect_pathはLogInや、SignUpや、InitializedProfileなど、
  // 特殊なタイミングで redirectOrPushというmixin関数で画面遷移をする時に使われる。
  // redirect_type一覧( 基本的に自身がfromのときの性質 )
  // none: redirect_pathを消す
  // take_over: redirect_pathを引き継ぐ
  // self: 自信がredirect_pathになる
  // 参考
  // https://docs.google.com/spreadsheets/d/13P9svukZVfCNOs8FZ5-EMOs_8LesaKzz0kOgCAhZDaA/edit#gid=0&range=A1:B2

  // self→take_overのとき、redirect_pathはfromのpathになる
  if (from.meta.redirect_type == "self" && to.meta.redirect_type == "take_over") {
    return from.path
  }
  // take_over→take_overのとき、redirect_pathは前のtake_overを引き継ぐことになる。
  if (from.meta.redirect_type == "take_over" && to.meta.redirect_type == "take_over") {
    return from.query.redirect
  }
  return null
}



export default router