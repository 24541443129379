<template>
  <VSelect v-bind:value="selectValue" @change="$emit('change', $event);" v-bind="$attrs">
    <template v-slot:item="{item}">
      <VContainer fluid>
        <VRow>
          <VCol cols="12">
            {{item[$attrs["item-text"]]}}
          </VCol>
        </VRow>
      </VContainer>
    </template>
    </VSelect>
</template>

<script>
// import { UsecaseServiceName } from "hfapi"
export default {
  inheritAttrs: false,
  name: "BaseMultipleLineSelect",
  model: {
    prop: "selectValue",
    event: "change"
  },
  props: [
    "selectValue",
  ],
  data() {
    return {
      console: console
    }
  }
}
</script>

<style scoped>
</style>
