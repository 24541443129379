<template>
  <v-snackbar :color="$store.state.flash.type" top :timeout="3000" v-model="$store.state.flash.isShown" style="white-space:pre-wrap;" data-test="snackbar">
    {{$store.state.flash.message}}
  </v-snackbar>
</template>

<script>
export default {
}
</script>

<style>

</style>