<script>
export default {
  inheritAttrs: false,
  name: "BaseButtonPrimary",
  render(h){
    const children = Object.keys(this.$slots).map(slot =>
      h("template", { slot }, this.$slots[slot])
    );
    return h("VBtn", {
      props: {
        ...this.$props,
        "color": "primary",
        "x-large": true,
        rounded: true,
        "min-width": "240px"
      },
      attrs: {...this.$attrs},
      on: this.$listeners,
      scopedSlots: this.$scopedSlots
    }, children);
  }
}
</script>
