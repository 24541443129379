<script>
export default {
  inheritAttrs: false,
  name: "BaseButton",
  render(h){
    const children = Object.keys(this.$slots).map(slot =>
      h("template", { slot }, this.$slots[slot])
    );
    return h("VBtn", {
      props: {
        ...this.$props,
        rounded: true,
      },
      attrs: this.$attrs,
      on: this.$listeners,
      scopedSlots: this.$scopedSlots
    }, children);
  }
}
</script>
