<script>

import { VTextField } from 'vuetify/lib'

export default {
  name: "BaseTextField",
  extends: VTextField,
  props: {
    
  },
}

</script>