import posthog from "posthog-js";

export default {
  install(Vue, options) {
    const key = process.env.VUE_APP_POSTHOG_KEY;
    if (!key) {
      console.warn("Posthog key not found. Posthog will not be initialized.");
      Vue.prototype.$posthog = new Proxy(
        {},
        {
          get(target, property, receiver) {
            return () => {
              console.warn(
                `Posthog method '${property}' was called, but Posthog is not initialized.`
              );
            };
          },
        }
      );
      return;
    }
    Vue.prototype.$posthog = posthog.init(key, {
      api_host: "https://posthog.job-tree-japan.com",
      ui_host: "https://app.posthog.com",
    });
  },
};
