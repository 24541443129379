<script>

import { VChip } from 'vuetify/lib'

export default {
  name: "BaseChip",
  extends: VChip,
  props: {
    
  },
}

</script>