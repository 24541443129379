<template>
  <div>
    <VAppBar app color="primary accent-4" dark shouldExtend>

      <VToolbarTitle>
          <VImg src="/header-logo.png" @click="$router.push({name: 'TopPage'})" contain width="120px" class="my-3"></VImg>
          <!-- <img src="" alt=""> -->
          <!-- <span class="display-1 text-capitalize" style="line-height: 1.5rem;">Job Tree Japan</span> -->
      </VToolbarTitle>

      <VSpacer></VSpacer>

      <div>
        <!-- メニューボタン -->
        <VAppBarNavIcon @click="drawer = !drawer" data-test="nav-icon"></VAppBarNavIcon>
      </div>

      <!-- Languageボタン -->
      <VMenu offset-y>
        <template v-slot:activator="{ on }">
          <VBtn text v-on="on">
            <VIcon>mdi-web</VIcon>
          </VBtn>
        </template>
        <VList>
          <VListItem v-for="lang in languages" @click="$store.commit('setPageLang', lang.symbol)" :key="lang.symbol">
            <VListItemTitle>{{ lang.emoji + lang.name }} <span v-if="$i18n.locale==lang.symbol">✔︎</span></VListItemTitle>
          </VListItem>
        </VList>
      </VMenu>


    </VAppBar>

    <!-- メニューボタンのDrawer -->
    <VNavigationDrawer right v-model="drawer" app class="white darken-1" hide-overlay data-test="nav-drawer">
      <VList>
        <div v-for="item in items" :key="item.title" >
          <VListItem @click="$router.push(item.route)" v-if="isShowItem(item)" :data-test="item.dataTest">
            <VListItemContent class="black--text">
                <span>{{item.title}}</span>
            </VListItemContent>
          </VListItem>
        </div>
      </VList>
    </VNavigationDrawer>
  </div>
</template>

<script>
export default {
  data(){
    return {
      drawer: false,
      items: [
        { title: 'Jobs', route: '/recruitments', showType: "both", dataTest: "jobs" },
        { title: "Course", route: "/courses", showType: "both", dataTest: "courses" },
        { title: 'Events', route: '/events', showType: "both", dataTest: "events" },
        { title: 'Articles', route: '/articles', showType: "both", dataTest: "articles" },
        { title: 'Profile', route: '/profile', showType: "login", dataTest: "profile" },
        { title: 'Login', route: '/log_in', showType: "notLogin", dataTest: "signin" },
        { title: 'SignUp', route: '/sign_up', showType: "notLogin", dataTest: "signup" },
        { title: 'LogOut', route: '/log_out', showType: "login", dataTest: "signout" },
      ],
      languages: [
        { name: "Japanese", symbol: "ja", emoji: "🇯🇵" },
        { name: "English", symbol: "en", emoji: "🇺🇸" }
      ],
    }
  },
  computed: {
  },
  methods: {
    isShowItem(item){
      switch (item.showType) {
        case "both":
          return true
        case "login":
          return this.isSignIn
        case "notLogin":
          return !this.isSignIn
        default:
          return false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
