"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "event_application": { "event_id": null, "apply_campaign_name": null, "apply_campaign_source": null, "apply_campaign_term": null, "apply_campaign_medium": null, "apply_campaign_content": null, "apply_campaign_cretaed_at": null, "apply_landing_page": null } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "event_application": { "event_id": null, "apply_campaign_name": null, "apply_campaign_source": null, "apply_campaign_term": null, "apply_campaign_medium": null, "apply_campaign_content": null, "apply_campaign_cretaed_at": null, "apply_landing_page": null } });
    }
    build(key) { return {}[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.patch("/jtj/commands/user__apply_event", { params });
    }
};
