import moment from "moment";
import {updatedDiff} from 'deep-object-diff'

export default {
  data(){
    return {
      moment: moment,
      validation: {
        required: value => !!value || typeof value == "boolean" || this.$t('shared.Validations.required'),
        notBlank: value => (!!value && value.length > 0) || this.$t('shared.Validations.required'),
        email: value => /.+@.+\..+/.test(value) || "E-mail isn't valid",
        longer6: value => !value || value.length >= 6 || "This field must be at least 6 characters long.",
        japanPostalCode: value => /^[0-9]{3}-[0-9]{4}$/.test(value) || /^[0-9]{7}$/.test(value) || this.$t('shared.Validations.japan_postal_code')
      }
    }
  },
  computed: {
    user() { return this.$store.state.user },
    window(){ return window },
    isSignIn(){
      return this.$store.getters["auth/isSignIn"]
    },
    locale(){
      return this.$i18n.locale
    },
  },
  methods: {
    assign(target, source){
      return Object.assign({}, target, source)
    },
    flash(message, type) {
      if(Array.isArray(message)){ message = message.join("\n") }
      this.$store.commit('flash/set', {message: message, type: type})
    },
    getParam(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[[]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    master(key){
      this.$store.dispatch("master/fetch", key)
      return this.$store.state.master[key]
    },
    randHash(){
      const length = 16
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    copy(obj){
      return JSON.parse(JSON.stringify(obj))
    },
    tl(langObj){
      return langObj[this.$i18n.locale] || langObj["en"];
    },
    redirectOrPush(path) {
      const to = this.$route.query.redirect
      if (to && to != this.$route.path) this.$router.push(this.$route.query.redirect)
      else this.$router.push(path)
    },
    getCookie(cookieName) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${cookieName}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    scrollTo(element){
      var headerOffset = 60;
      console.log(element);
      console.log(element.getBoundingClientRect());
      var elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      console.log(elementPosition);
      var offsetPosition = elementPosition - headerOffset;
      this.window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
    // assignをする間にTargetが更新されていることを加味するため。
    assignConsideringTargetUpdate(target, source, ignoreKeys=[]){
      let diffData = updatedDiff(source, target) // sourceになくて、targetにあるぶんを取得
      ignoreKeys.forEach(key=>delete diffData[key])
      let assignedData = Object.assign(source, diffData) // sourceにdiffDataを追加
      target.assign(assignedData) // asssign
    }
  },
}