export default function(){
  // 配列周り
  Array.prototype.remove = function(item) {
    const index = this.indexOf(item);
    if (index > -1) {
      this.splice(index, 1);
    }
    return this
  };
  Array.prototype.distinct = function() {
    return [...new Set(this)]
  };
  Array.prototype.isInclude = function(item) {
    const index = this.indexOf(item);
    if (index > -1) {
      return true
    } else {
      return false;
    }
  }
}

