export default function(Vue){
  // Baseという名前のComponentを一括ロード
  var requireComponent = require.context(
    "../components",
    true,
    /Base[A-Z]\w+\.(vue|js)$/
  );
  requireComponent.keys().forEach(function(fileName) {
    var baseComponentConfig = requireComponent(fileName);
    baseComponentConfig = baseComponentConfig.default || baseComponentConfig;
    var baseComponentName =
      baseComponentConfig.name ||
      fileName.replace(/^.+\//, "").replace(/\.\w+$/, "");
    Vue.component(baseComponentName, baseComponentConfig);
  });
}