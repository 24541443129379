<script>
import { VCheckbox } from 'vuetify/lib'

export default {
  name: "BaseCheckbox",
  extends: VCheckbox,
  props: {
    
  },
}
</script>
