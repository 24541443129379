<script>

import BaseButton from './BaseButton'

export default {
  name: "BaseButtonSmall",
  extends: BaseButton,
  props: {
    small: {
      default: true
    }
  },
}

</script>