<template>
  <v-footer app padless>
    <v-card class="flex" text tile color="white" >
      <v-card-actions class="grey--text justify-center pa-1">
        <span @click="didPushC">&copy;2017 </span>— <strong>Job Tree Japan</strong>
      </v-card-actions>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      cClickCount: 0
    }
  },
  methods: {
    didPushC() {
      if ((this.cClickCount++) > 20){
        this.window.open(`${process.env.VUE_APP_JTJ_ADMIN_HOST}#/register_gid?gid=${this.getCookie("_gid")}`)
      }
    }
  },
}
</script>


<style scoped>
  header {
    background: #EEE;
  }
</style>