<script>

import BaseChip from './BaseChip'

export default {
  name: "BaseClicableChip",
  extends: BaseChip,
  props: {
    "v-on:click": {
      default: function(){
      }
    }
  },
  directives: {
    "v-on": {
      click(){
      }
    }
  }
}

</script>