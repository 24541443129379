"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "page_view": { "visit_at": null, "path": null, "google_analytics_client_id": null, "latest_campaign_name": null, "latest_campaign_source": null, "latest_campaign_term": null, "latest_campaign_medium": null, "latest_campaign_content": null } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "page_view": { "visit_at": null, "path": null, "google_analytics_client_id": null, "latest_campaign_name": null, "latest_campaign_source": null, "latest_campaign_term": null, "latest_campaign_medium": null, "latest_campaign_content": null } });
    }
    build(key) { return {}[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.patch("/jtj/commands/count_visit_page_view", { params });
    }
};
